import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '@/components/Layout';
import Content from '@/components/Content';

const PregnantRisks = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(`en`);
  }, []);

  return (
    <Layout
      title="Food during pregnancy ? Eat serenely with La fraise !"
      description="Food for pregnant women with La fraise. Discover all the forbidden foods during pregnancy? Download for free our mobile application La fraise, eating during pregnancy."
    >
      <Content>
        <h1 className="mb-16 mr-12 text-4xl font-bold">
          The risks associated with pregnancy
        </h1>

        <div>
          <div className="mb-16" id="toxoplasmosis">
            <h2 className="text-2xl mb-8 font-bold">Toxoplasmosis</h2>
            <div>
              <p>
                Toxoplasmosis is a{` `}
                <strong>
                  risky disease for pregnant women and their babies.
                </strong>
                <br />
                It promotes premature births. The "toxo" can also cause
                neurological or ophthalmic problems in the fetus. This is why we
                systematically prescribe, at the beginning of pregnancy, a test
                related to toxoplasmosis.
              </p>
              <br />
              <strong>
                How do you know if you are immune to toxoplasmosis?
              </strong>
              <br />
              <br />
              <p>
                Beyond the serological test, there is a good chance that you be
                immune to toxoplasmosis:
              </p>
              <br />
              <ul>
                <li>
                  - if, before your pregnancy, you were a fan of raw meat;
                </li>
                <li>- or if you have lived with a kitten.</li>
              </ul>
              <br />
              <p>
                If you have symptoms such as fever, lymph nodes and fatigue
                after eating undercooked meat, you have may have contracted
                toxoplasmosis.
                <br />
                Take a blood test. You will know if you are immune against
                toxoplasmosis and avoid any risk during the pregnancy.
              </p>
              <br />
              <strong>
                How to guard against toxoplasmosis when you are not immune?
              </strong>
              <br />
              <p>
                If your blood test revealed that you were not immune to toxo,
                here are the <strong>precautions</strong> you can take to
                protect your little one. Avoid eating:
              </p>
              <br />
              <ul>
                <li>- undercooked, raw, smoked or dried meat;</li>
                <li>- raw, smoked or dried deli meats;</li>
                <li>- raw milk cheeses;</li>
                <li>- uncooked seafood.</li>
              </ul>
              <br />
              <strong>Wash your hands well</strong> after handling meat or
              earthy vegetables.
              <br />
              <br />
              <strong>Wash the fruits, vegetables and herbs</strong> you eat,
              especially if they come from the garden.
              <br />
              <br />
              <p>
                If you <strong>have a cat</strong>, ask your partner to change
                their litter box. If you are alone with your cat, change its
                litter regularly, using gloves. And wash your hands after
                petting your cat.
              </p>
            </div>
          </div>

          <div className="mb-16" id="listeriosis">
            <h2 className="text-2xl mb-8 font-bold">Listeriosis</h2>
            <div>
              <p>
                Listeriosis is caused by a <strong>bacterium</strong> called
                Listeria monocytogenes. It thrives in food. Listeriosis remains
                quite rare in our societies. It is indeed limited by the
                cleaning of food and pasteurization. Despite these precautions,
                Listeria can be found in certain foods. In pregnant women,
                contracting listeriosis is{` `}
                <strong>dangerous for the baby.</strong>
              </p>
              <br />
              <h3 className="font-bold">
                What are the symptoms of listeriosis?
              </h3>
              <br />
              <p>
                The problem is that the symptoms of listeriosis are close to the
                inconveniences associated with pregnancy. Nausea, vomiting,
                stomach aches, headaches are signs of listeriosis.{` `}
                <strong>Fever</strong> is an additional symptom that may alert
                you. In case of {` `}
                <strong>listeriosis in pregnancy</strong>, taking {` `}
                <strong>antibiotics</strong> is inevitable.
              </p>
              <br />
              <h3 className="font-bold">How to avoid getting listeriosis?</h3>
              <br />
              <ul>
                <li>
                  - Avoid all raw food: raw meat and fish, uncooked seafood, raw
                  sprouted seeds, raw milk cheeses, uncooked smoked fish and
                  meats;
                </li>
                <li>
                  - Wash your hands often, especially if you touch raw meat and
                  fish to cook them;
                </li>
                <li>
                  - When you eat cheese – cooked obviously! -, {` `}
                  <strong>remove the crust</strong> as this is where bacteria
                  grow;
                </li>
                <li>
                  - Do not keep <strong>food scraps</strong>;
                </li>
                <li>
                  - Regularly disinfect your refrigerator and check that its
                  temperature never exceeds 4°C;
                </li>
                <li>- Avoid defrosting food in the ambient air.</li>
              </ul>
              <br />
              In summary, proper cooking of food and perfect hygiene limit the
              risk of listeriosis. Obesity in pregnant women entails{` `}
              <strong>risks for the mother and the baby</strong>.
              <br />
              <br />
              For the mother, the risks of developing a {` `}
              <a href="#diabetes" className="underline">
                gestational diabetes
              </a>
              , to <strong>have hypertension</strong> and to be imposed
              caesarean section are higher with obesity. As for the baby, he
              risk of arriving prematurely, of being born overweight and of
              suffering birth defects.
            </div>
          </div>

          <div className="mb-16" id="diabetes">
            <h2 className="text-2xl mb-8 font-bold">Diabetes</h2>
            <div>
              <p>
                Whether it is present before motherhood or develops with
                pregnancy, diabetes can promote malformations in babies and
                various health problems. Of course, if you had diabetes before
                you got pregnant, pregnancy won't fix the disease. Other factors
                favor
                {` `} <strong>gestational diabetes</strong>:
              </p>
              <br />
              <ul>
                <li>- previous gestational diabetes;</li>
                <li>- family history;</li>
                <li>- overweight;</li>
                <li>- pregnancy at over 35 years old.</li>
              </ul>
              <br />
              To limit the risks associated with gestational diabetes, we advise
              you to adopt a specific diet, to practice regular physical
              activity, to be followed diligently by your doctor and to opt for
              {` `}
              {` `}
              <strong>injections of insulin</strong> that will regulate your
              blood sugar levels.
            </div>
          </div>

          <div className="mb-16" id="salmonellosis">
            <h2 className="text-2xl mb-8 font-bold">Salmonellosis</h2>
            <div>
              <p>
                <strong>Salmonellosis</strong> is an intestinal infection caused
                by the Salmonella bacteria.
              </p>
              <br />
              <p>
                Salmonella are found in the stools of many animals, including
                mammals (including humans), reptiles, amphibians and birds.
              </p>
              <br />
              <p>
                Anyone who consumes food or water contaminated with Salmonella
                is likely to <strong>become infected</strong>. Foods most
                commonly contaminated with Salmonella are{` `}
                <strong>raw fruits</strong> and
                {` `}
                <strong>raw vegetables</strong> (such as sprouted seeds and
                cantaloupes), <strong>raw eggs </strong>, unpasteurized {` `}
                <strong>dairy products</strong> and undercooked {` `}
                <strong>meat</strong> and <strong>poultry</strong>.
              </p>
              <br />
              <p>
                Water is more rarely contaminated, except in countries where
                sanitation facilities are deficient.
              </p>
              <br />
              <p>
                While salmonellosis will not cause complications in most healthy
                adults, this bacterial infection may be more serious in
                populations with weak immune systems, such as babies, pregnant
                women, and immunocompromised people.
              </p>
              <br />
              <p>To limit the risk, it is advisable to:</p>
              <br />
              <ul>
                <li>- Store raw foods separately from cooked foods;</li>
                <li>- Wash your hands before cooking;</li>
                <li>- Wash raw foods, countertops and kitchen utensils;</li>
                <li>
                  - Reheat leftover food at a high temperature to destroy any
                  bacteria;
                </li>
                <li>- Regularly clean the refrigerator with bleach.</li>
                <li>
                  - Do not consume raw milk and unpasteurized dairy products,
                  such as raw sprouted seeds, raw meat or fish.
                </li>
              </ul>
              <br />
              <p>
                Proper cooking is recommended for animal products such as meat,
                fish or eggs. And it is also advisable to follow the news,
                because health alerts are launched in the event of potentially
                contaminated food.
              </p>
            </div>
          </div>

          <div className="mb-16" id="methylmercury-poisoning">
            <h2 className="text-2xl mb-8 font-bold">Methylmercury poisoning</h2>
            <div>
              <p>
                Mercury, especially in its organic form, {` `}
                <strong>methylmercury</strong>, is a powerful neurotoxicant for
                the <strong>fetus</strong>: abnormalities in the psychomotor
                development of children have been associated with maternal
                mercury exposure during pregnancy without her showing {` `}
                <strong>symptoms of intoxication.</strong>
              </p>
              <br />
              <p>
                According to the Public Health France study, with regard to the
                "nutritional benefits linked to the consumption of fish
                (essential fatty acids, proteins, vitamins, minerals and trace
                elements)", the Agency recommends that pregnant women eat fish
                {` `}
                {` `}
                <strong>twice a week maximum</strong> including fish fatty
                (salmon, mackerel, sardines, anchovies, smoked trout, herring…)
                and to diversify the species of fish consumed.
              </p>
              <br />
              <p>
                It specifies to avoid as a precaution to consume the
                contaminated fish such as sharks, lampreys or swordfish, and to
                {` `}
                {` `}
                <strong>"limit consumption"</strong> other fish "likely to be
                highly contaminated" in mercury, including tuna, pike or sea
                bream.
              </p>
            </div>
          </div>

          <div className="mb-16" id="fetal-alcoholism">
            <h2 className="text-2xl mb-8 font-bold">Fetal alcoholism</h2>
            <div>
              <p>
                When a woman consumes alcohol while she is pregnant, the alcohol
                is transmitted directly to the baby through the circuit blood.
                Alcohol consumption during pregnancy can cause fetal alcohol
                syndrome, a serious condition that can harm the child for life.
              </p>
              <br />
              <p>
                Children with FAS have developmental disabilities, learning,
                behavior and social skills. He is therefore better not to
                consume alcohol at all.
              </p>
            </div>
          </div>

          <div className="mb-16" id="hypotension">
            <h2 className="text-2xl mb-8 font-bold">Hypotension</h2>
            <div>
              <p>
                During the first trimester, blood pressure tends to decrease
                decrease naturally in pregnant women. Indeed, the circulatory
                circulatory system is fully directed to the placenta and the
                fetus fetus while significant hormonal changes at this stage
                cause dilation of the arteries and veins which results in a
                slight drop drop in voltage.
              </p>
              <br />
              <p>
                This is the reason why you can feel a sensation dizziness. But
                as soon as the 2nd begins trimester, blood pressure should
                return to normal. She does not will undergo any further
                modifications only at the beginning of the 3rd trimester, in
                which cases of hypertension are revealed generally.
              </p>
              <br />
              <h3 className="font-bold">
                How to detect hypotension during pregnancy?
              </h3>
              <br />
              <ul>
                <li>- headaches,</li>
                <li>- increased fatigue,</li>
                <li>- cold sweats,</li>
                <li>- auditory ringing,</li>
                <li>- visual disturbances,</li>
                <li>- paleness of the face,</li>
                <li>- a drop in heart rate.</li>
              </ul>
            </div>
          </div>

          <div className="mb-16" id="contractions-uterus">
            <h2 className="text-2xl mb-8 font-bold">
              Contractions of the uterus
            </h2>
            <div>
              <p>
                There are different types of contraction that occur at different
                times of pregnancy. Each type of contraction has its own
                function.
              </p>
              <br />
              <p>
                Early contractions are contractions that can occur before the
                36th week of pregnancy. We can them recognize by the fact that
                the uterus contracts about three times per hour in relatively
                short intervals, and that the pain gradually intensifies. These
                contractions can be accompanied by a watery or even bloody
                discharge.
              </p>
              <br />
              <p>
                Some plants can promote the onset of contractions; they are to
                be avoided.
                <br />
                And if the contractions are not the sign of a birth premature,
                they can nevertheless be a warning signal of your body
                indicating you a physical overload and/or emotional.
              </p>
              <br />
              <p>Rest and relaxation could do you good.</p>
            </div>
          </div>

          <div className="mb-16" id="premature-delivery">
            <h2 className="text-2xl mb-8 font-bold">Premature delivery</h2>
            <div>
              <p>
                Soda and pregnancy, it's not a good cocktail. Contrary to what
                you might think, sugary soft drinks are acidic. They therefore
                promote stomach aches.
                <br />
                Sugary sodas also promote {` `}
                <a href="#diabetes" className="underline">
                  gestational diabetes
                </a>
                and increase the risk of maternal obesity. In lower proportions,
                soda promotes weight gain and premature deliveries. A Danish
                study showed correlation between the consumption of sweetened
                drinks containing containing aspartame or sugar, and the risk of
                childbirth premature.
              </p>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default PregnantRisks;

export const query = graphql`
  query {
    locales: allLocale(
      filter: { ns: { in: ["general"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
